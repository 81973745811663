var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"slider slider-animation bv xz"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[(_vm.campaign.sliders)?_c('carousel',{attrs:{"items":1,"nav":false,"autoplay":true,"autoplayTimeout":2000}},_vm._l((_vm.campaign.sliders),function(slider){return _c('a',{key:slider.id,attrs:{"href":slider.url}},[_c('img',{attrs:{"src":_vm.img_base_url + slider.image}})])}),0):_vm._e()],1)]),(_vm.campaign.campaign)?_c('div',{staticClass:"all-campaign"},[_c('div',{staticClass:"single-campaign"},[_c('div',{staticClass:"campaign-heading",style:('background-color:' + _vm.campaign.campaign.bg_color)},[_c('div',{staticClass:"heading-content"},[_c('h4',{style:({
                     color: _vm.campaign.campaign.text_color,
                     'margin-top': '50px',
                   })},[_vm._v(" "+_vm._s(_vm.campaign.campaign.campaign_name)+" ")]),_c('div',{staticClass:"coundow"},[(_vm.campaign.campaign.end_date)?_c('Countdown',{attrs:{"deadline":_vm.campaign.campaign.end_date}}):_vm._e()],1)])]),_c('div',{staticClass:"campaignn-products",style:('background-color:' + _vm.campaign.campaign.bg_color)},[(_vm.campaign.campaign.campaign_products)?_c('div',{staticClass:"row"},_vm._l((_vm.campaign.campaign
                     .campaign_products),function(product,index){return _c('div',{key:index,staticClass:"col-lg-3 col-sm-6 col-md-4 col-xs-6 width-20"},[_c('div',{staticClass:"product-card"},[_c('div',{staticClass:"prodict-card-body"},[_c('router-link',{attrs:{"to":{
                           name: 'product',
                           params: { slug: product.product.slug },
                         }}},[(product.product.product.product_image)?_c('img',{attrs:{"src":_vm.img_base_url + product.product.product_image[0].product_image}}):_vm._e()]),_c('div',{staticClass:"product-detail"},[_c('h4',[_c('router-link',{staticClass:"product-link",attrs:{"to":{
                               name: 'product',
                               params: { slug: product.product.slug },
                             }}},[_vm._v(_vm._s(product.product.name))])],1),_c('p',{staticClass:"price"},[_c('span',{staticClass:"price-new"},[_vm._v(_vm._s(product.product.price))]),(product.product.discount)?_c('span',{staticClass:"price-old"},[_vm._v(_vm._s(product.product.sale_price))]):_vm._e()])])],1),_c('div',{staticClass:"product-card-footer"},[_c('button',{staticClass:"btn btn-primary btnQuick",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.quick_v_product_id = product.product.id}}},[_vm._v(" view ")])])])])}),0):_c('div',{staticStyle:{"text-align":"center","width":"50%","margin":"auto","background":"#fff","border-radius":"10px","box-shadow":"3px 3px 3px #ddd","/* height":"150px","*/\n                   /* padding":"104px","*/\n                   font-weight":"600","font-style":"italic","color":"#4a4242fa","text-transform":"uppercase","padding":"30px"}},[_c('h4',[_vm._v("No Product Found Against "+_vm._s(_vm.campaign.campaign_name))])])])])]):_vm._e()]),(_vm.quick_v_product_id)?_c('quickView',{attrs:{"quick_v_p_id":_vm.quick_v_product_id},on:{"clicked":function($event){return _vm.closedModal($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }